export const getAccessToken = () => {
  const token = localStorage.getItem('token');

  try {
    return JSON.parse(token as string);
  } catch {
    return token;
  }
};

export default getAccessToken;
